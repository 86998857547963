import apiService from "@/core/services/apiService";

export type State = { classes: object };
import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Mutations} from "@/store/enums/store.enums";
import {DashboardMutation, DashboardActions} from "@/modules/dashboard/store/enums";

@Module
export default class Store extends VuexModule {

    renderKey = 1;
    stats = {};

    get getDashboardRenderKey() {
        return 'dashboard-' + this.renderKey;
    }

    get getDashboardReceivingStats() {
        return this.stats['receiving'] ?? [];
    }

    get getDashboardOrderStats() {
        return this.stats['orders'] ?? [];
    }

    get getDashboardCheckoutStats() {
        return this.stats['checkout'] ?? [];
    }

    @Mutation
    [DashboardMutation.SET_RENDER_UPDATE]() {
        this.renderKey++;
    }

    @Mutation
    [DashboardMutation.SET_STATS]({module, data, endpoint}) {
        this.stats[module] = data;
    }

    @Action
    [DashboardActions.GET_STATS](params) {

        return new Promise<void>((resolve, reject) => {


            const {endpoint, module} = params;

            if (!endpoint) {
                this.context.commit(Mutations.SET_ERROR, 'Endpoint missing');
                reject();
            }

            apiService.post(`${module}/${endpoint}`, params).then((response) => {

                const data = response.data || null;

                const _verified = apiService.verifyApiResult(data);
                if (!_verified) {
                    this.context.commit(Mutations.SET_ERROR, data);
                    return reject();
                }

                this.context.commit(DashboardMutation.SET_STATS, {
                    module,
                    endpoint,
                    data: data.result,
                });

                resolve();

            }).catch((response) => {

                this.context.commit(Mutations.SET_ERROR, response);
                reject();

            });

        });

    };

}
